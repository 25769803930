import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Vote from "./pages/Vote";
import Admin from "./pages/Admin";
import ErrorPage from "./pages/ErrorPage";

import './styles/global.css'
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<App />} />
      <Route path='*' element={<ErrorPage/>} />
      {/* <Route path="vote" element={<Vote />} /> */}
      <Route path="admin" element={<Admin />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals