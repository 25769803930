

import React from "react";

function SimpleBusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.45 38.31" fill={props.colorMain} stroke={props.colorMain} className={props.className}>
      <path d="M49.36,10.37h-7.73v12.96H3v7.8H7.57c.38-4.06,3.8-7.24,7.96-7.24s7.58,3.18,7.96,7.24h14.77c.27-3.55,2.89-6.62,6.56-7.27,4.35-.78,8.51,2.11,9.29,6.46,.05,.27,.08,.54,.1,.81h4.68v-8.91l-9.52-11.84Z" strokeLinejoin="round" strokeLinecap="round" />
      <polygon points="47.7 6.55 40.32 3.75 29.13 3.75 4.03 3.75 4.03 5.55 4.03 19.54 37.56 19.54 37.38 6.55 47.7 6.55" strokeLinejoin="round" strokeLinecap="round" fill="#494646" stroke="#494646" />
      <circle cx="15.62" cy="31.73" r="3.23" strokeWidth={5} fill="#494646" stroke="#494646" />
      <circle cx="46.23" cy="31.73" r="3.23" strokeWidth={5} fill="#494646" stroke="#494646" />
      <rect x="5.99" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
      <rect x="14.29" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
      <rect x="22.59" y="8.43" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
      <rect x="30.89" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
    </svg>
  )
}

export default SimpleBusIcon;
