import React, { useMemo, useEffect } from "react";
import Map from "./Map"
import Settings from "./Settings"
import BusDisplay from './BusDisplay'

import LanguageButton from "./LanguageButton";
import i18n from "i18next";
import VotePopup from "./VotePopup";
import Header from "./Header";

const App = () => {
    useEffect(() => {
        window.scrollTo(0, 1)
    }, [])
    return (
        <html lang='en' >
            <head > { /* <link rel="shortcut icon" href="" /> */}
                <meta name="theme-color" value='#36C1A3' />
            </head>
            <body className="">
                <div className="!z-[-1] absolute top-0 left-0 w-full h-full">
                    <Map />
                </div>
                <LanguageButton />
                {/* <VotePopup /> */}

                <Header></Header>

            </body>
        </html >
    )
}

export default App