import React, { useState } from "react";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Login = (props) => {
  const adminEmails = ["chanatan.melmel@gmail.com", "rayongcd@gmail.com", "nareerat.bonus@gmail.com", "bonus.nareerat@gmail.com", 'phumsan.real@gmail.com'];
  const [showingError, setShowingError] = useState(false);

  return (
    props.showingPopup && (
      <div className="!z-50 flex justify-center items-center absolute w-full h-full top-0 left-0 backdrop-blur-sm bg-black/30 ">
        <div className="bg-white p-6 text-center rounded-md shadow-md items-center flex flex-col translate-y-[-10px]">
          <h1 className="heading-1 mb-6 !w-[20ch] ">
            Please login to access data
          </h1>
          <GoogleLogin
            clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
            onSuccess={(credentialResponse) => {
              const token = credentialResponse.credential;
              const decoded = jwtDecode(token);
              if (adminEmails.includes(decoded.email)) {
                props.setIsLogin(true);
                props.setShowingPopup(false);
              } else {
                setShowingError(true);
                console.log("error");
              }
            }}
            onError={() => {
              console.log("*Login Failed");
            }}
          />
          {showingError && (
            <p className="paragraph !text-2xl text-red-600">
              user is not an admin
            </p>
          )}
        </div>
      </div>
    )
  );
};

export default Login;
