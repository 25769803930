import React from 'react'
import logoFull from "./assets/RYCD-logo-full.png"
import logo from "./assets/RYCD-logo.png"
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const Header = () => {
    const { t, i18n } = useTranslation();

    return (
        <main className="sm:px-3 sm:py-3 px-[4rem] py-[2rem] sm:text-center">
            <div className="sm:translate-x-[-1rem] sm:gap-1 feather-blur items-center inline-flex px-2 sm:justify-between select-none gap-2">
                <a draggable="false" href="https://www.rayongcity.dev/" className="sm:hidden block w-[calc(10vw+5rem)] translate-y-2"><img draggable="false" src={logoFull} alt="RYCD Logo" /></a>
                <a draggable="false" href="https://www.rayongcity.dev/" className="sm:inline-block hidden w-[3.5rem] mr-2 sm:mr-0"><img draggable="false" src={logo} alt="RYCD Logo" /></a>
                <div className="flex flex-col sm:text-left">
                    <h1 className="font-opensan font-bold text-clamp-lg text-logo-gray uppercase tracking-tighter">Rayong Mini Bus Map</h1>
                    <h1 className="font-jipatha font-bold text-clamp-lg text-logo-gray uppercase leading-4">{t("เส้นทางบริการรถสองแถวระยอง")}</h1>
                </div>
            </div>



        </main>
    )
}

export default Header