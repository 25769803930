import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';


const firebaseConfig = {
    apiKey: "AIzaSyCK8Qu4y6dfeHTpHg89xAq-n-t3o1kQ-ms",
    authDomain: "rayong-mini-bus-map.firebaseapp.com",
    projectId: "rayong-mini-bus-map",
    storageBucket: "rayong-mini-bus-map.appspot.com",
    messagingSenderId: "851906839838",
    appId: "1:851906839838:web:7c62843d84014b55d4b50f",
    measurementId: "G-S7FMK5MEEC"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const addToDatabase = async (data) => {
    const getTimestamp = () => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const currentDateTime = new Date();
        return formatter.format(currentDateTime);
    }

    try {
        const docRef = await addDoc(collection(db, "vote"), {
            timestamp: getTimestamp(),
            comment: data.comment1,
            suggestion: data.comment2,
            route: data.routeIndex,
            userGender: data.gender,
            userAge: data.age,
            userJob: data.job,
            haveAddress: data.haveAddress,
        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const fetchPost = async (set) => {

    await getDocs(collection(db, "vote"))
        .then((querySnapshot) => {
            const newData = querySnapshot.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));
            set(newData)
        })

}