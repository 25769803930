import route1 from './route1.json'
import route2 from './route2.json'
import route3 from './route3.json'
import route4 from './route4.json'
import route5 from './route5.json'
import route6 from './route6.json'
import route7 from './route7.json'
import route8 from './route8.json'
import route9 from './route9.json'
import route10 from './route10.json'
import route11 from './route11.json'
import route12 from './route12.json'
import route13 from './route13.json'
import route14 from './route14.json'
import route15 from './route15.json'
import route16 from './route16.json'
import route17 from './route17.json'
import route18 from './route18.json'
import route19 from './route19.json'
import route20 from './route20.json'
import route21 from './route21.json'
import route22 from './route22.json'
import route23 from './route23.json'

const routes = [
    route1,
    route2,
    route3,
    route4,
    route5,
    route6,
    route7,
    route8,
    route9,
    route10,
    route11,
    route12,
    route13,
    route14,
    route15,
    route16,
    route17,
    route18,
    route19,
    route20,
    route21,
    route22,
    route23,

]
export default routes;