import React, { useState } from 'react'
import ToggleSwitch from './ToggleSwitch'
import ArrowRightIcon from "./assets/arrow-right-solid.jsx"
import BusPickUpIcon from "./assets/pickup-normal.jsx"
import Bus6WheelIcon from "./assets/6wheel-normal.jsx"
import WaveIcon from "./assets/wave-icon.png"
import { ReactComponent as BusBigIcon } from './assets/bus-orange-white.svg'

import { formatEndName, formatColorToHex } from './formatter'
import { motion, AnimatePresence, color } from "framer-motion"

import { useTranslation } from "react-i18next";

const ToggleBusCard = (props) => {
    const toggleCheck = () => {
        props.setShowingRoute(props.id, !props.showing)
        props.handleCheckedAll()
    }
    const { t, i18n } = useTranslation();

    const [formattedEndName, setFormattedEndName] = useState(formatEndName(props.endName))
    const [colorHex, setColorHex] = useState(formatColorToHex(props.busColor))


    return (
        <motion.div
            className='flex items-center relative border-b-2 px-5 py-4'
            id={props.id} layout
            initial={{ opacity: 0, y: "-20px", scale: 1 }}
            animate={{ opacity: 1, y: "0px", scale: 1 }}
            exit={{ opacity: 0, y: "-20px", scale: 1 }}
            transition={{ duration: 0.080, type: "linear" }}
        >
            <div className='flex flex-col flex-1 z-30'>
                {/* -- route color line -- */}
                <span className='w-[5px] h-[calc(100%+4px)] absolute left-0 top-[-2px]' style={{ background: `${colorHex[0]}` }}></span>
                {/* -- route text -- */}
                <div className="flex justify-between items-center">
                    <span className="[word-spacing:-2.0px] font-jipatha sm:text-[1.8em] text-[2em] fill-slate-400 leading-9 sm:leading-3 flex items-center gap-1 whitespace-nowrap">
                        {t(props.startName)}
                        {props.endName != "" &&
                            <ArrowRightIcon className="sm:w-3 sm:h-3 w-4 h-4 fill-gray-600" />}
                        {t(formattedEndName[0][0])}
                        {
                            formattedEndName[0][1] &&
                            <ArrowRightIcon className="sm:w-3 sm:h-3 w-4 h-4 fill-gray-600" />
                        }

                        {t(formattedEndName[0][1])}
                        {
                            formattedEndName[1] && <img src={WaveIcon} className="sm:w-5 sm:h-5 w-7 h-7 fill-gray-600" />
                        }
                    </span>
                    <div className="basis-auto">
                        <ToggleSwitch toggleCheck={toggleCheck} checked={props.showing} />
                    </div>
                </div>
                <div className='flex gap-5'>
                    {
                        props.busType == "pickup" ? (
                            <BusPickUpIcon className={`w-[32%] stroke-[3]`} colorMain={colorHex[0]} colorStripe={colorHex[1]} />
                        ) : (
                            props.busType == "6wheel" ? (
                                <Bus6WheelIcon className={`w-[32%] stroke-[3]`} colorMain={colorHex[0]} colorStripe={colorHex[1]} />
                            ) : (
                                <BusBigIcon className={`w-[32%] stroke-[3]`} />
                            )
                        )
                    }
                    <div className='flex-1 pr-3 pt-2'>
                        <div className='flex justify-between flex-1 items-center '>
                            <h4 className="sm:text-[1.8em] font-jipatha text-[1.8rem] text-gray-700 leading-9 flex items-center gap-2 whitespace-nowrap">ระยะทาง</h4>
                            <h4 className="font-inter sm:text-lg text-xl text-gray-700 font-light fill-slate-400 leading-3 whitespace-nowrap"> {props.distance} <span className=" font-jipatha text-[1.8rem] text-gray-700 leading-9" >กม.</span></h4>
                        </div>
                        <div className='flex justify-between flex-1 items-center'>
                            <h4 className="sm:text-[1.8em] font-jipatha text-[1.8rem] text-gray-700 leading-9 flex items-center gap-2 whitespace-nowrap">ค่าโดยสาร</h4>
                            <h4 className="font-inter sm:text-lg  text-xl text-gray-700 font-light fill-slate-400 leading-3 whitespace-nowrap">{props.price} <span className="font-jipatha text-[1.8rem] text-gray-700 leading-9" >บาท</span></h4>
                        </div>
                    </div>
                </div >

                {/* -- bus color -- */}
                {/* <div className="flex-row flex gap-2">
                    {getBusColor(props.busColor)}
                    <span className="font-inter font-light text-xl text-gray-700">{props.busColor} bus</span>
                </div> */}

            </div >

        </motion.div >
    )
}

export default ToggleBusCard