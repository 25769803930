import React, { useEffect, useState } from 'react'
import ArrowRightIcon from "../components/assets/arrow-right-solid.jsx"
import routes from "../components/routes/special_routes/routes.js"
import Header from "../components/Header"
import logoFull from "../components/assets/RYCD-logo-full.png"
import { fetchPost } from "../components/firebase.js"
import { collection, getDocs } from "firebase/firestore";
import { PieChart } from 'react-minimal-pie-chart';
import routesJson from '../components/routes/special_routes/routes'
import { formatSpecialRouteColor } from '../components/formatter.js'
import { CSVLink } from 'react-csv';
import Login from '../components/admin_page/Login.jsx'
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { ReactComponent as RefreshIcon } from '../components/assets/refresh.svg'


const ExportButton = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.json)
    })

    return (
        <CSVLink data={data} filename={'route_vote.csv'} >
            <button className='px-3 sm:px-2 py-1 rounded-xl border-[#32a852] border-2 font-inter text-[#32a852] hover:bg-[#32a852] hover:text-white font-bold !text-sm'>Export File</button>
        </CSVLink>
    )

}


const TextCommentBox = (props) => {
    return (
        <div className="shadow-[0_10px_10px_10px_rgba(0,0,0,0.1)] rounded-lg pb-3 mb-7 bg-white">
            <h2 className='paragraph !text-4xl sm:!text-3xl px-3 py-[.7rem] !w-full'>{props.label}</h2>
            <div className='flex flex-col gap-1 min-h-[calc(20vh)] max-h-[calc(50vh)] rounded-md overflow-auto items-center mx-3 '>
                {
                    props.items.length > 0 ? props.items?.map((item, i) => (
                        item &&
                        <div className='flex bg-gray-100 w-full '>
                            <p className='paragraph p-2 !w-full'>
                                {item}
                            </p>
                        </div>
                    )
                    ) :
                        <p className='paragraph !w-auto text-center mt-[2rem] text-gray-400'>ยังไม่มีการลงคะแนน</p>
                }
            </div>
        </div>
    )
}

const PieChartBox = (props) => {

    const countData = (data) => {
        const countObject = {}
        for (let i = 0; i < data.length; i++) {
            const currentItem = data[i];
            if (!countObject[currentItem]) {
                countObject[currentItem] = 1;
            } else {
                countObject[currentItem]++;
            }
        }
        return countObject
    }

    const getSum = (data) => {
        let sum = 0
        Object.values(data).forEach(nums => {
            sum += nums
        });
        return sum
    }

    const formatNumber = (num, sum) => {
        if ((num / sum * 100 % 1) == 0) return num / sum * 100
        else if ((num / sum * 100 % .1) == 0) return parseFloat(num / sum * 100).toFixed(1)
        else return parseFloat(num / sum * 100).toFixed(2)
    }

    const formatRouteData = (countedDatas) => {
        const formattedDatas = []
        const sum = getSum(countedDatas)
        Object.entries(countedDatas).forEach(data => {
            const routeInfo = routesJson[parseInt(data[0]) + 1
            ]
            formattedDatas.push({
                title: `${routeInfo.start_name} - ${routeInfo.end_name}`,
                color: formatSpecialRouteColor(routeInfo.bus_color),
                value: data[1],
                percentage: `${formatNumber(data[1], sum)} %`,
            })
        });
        return formattedDatas
    }

    const formatData = (countedDatas) => {
        const formattedDatas = []
        const sum = getSum(countedDatas)
        const colors = ["#E06666", "#2C93F0", "#FBBC04", "#06D6A0", "#26547C", "#995ebF", "#f696Ad", "#F80866"]
        Object.entries(countedDatas).forEach((data, i) => {
            formattedDatas.push({
                title: `${data[0]} `,
                color: colors[i],
                value: data[1],
                percentage: data[1] >= 5 ? `${formatNumber(data[1], sum)} %` : ""
            })
        });
        return formattedDatas
    }

    const [pieChartData, setPieChartData] = useState([]);
    useEffect(() => {
        setPieChartData(props.isRouteData ? formatRouteData(countData(props.items)) : formatData(countData(props.items)))
    }, [props.items])

    return (
        <div className="shadow-[0_10px_10px_10px_rgba(0,0,0,0.1)] rounded-lg pb-7 mb-7 bg-white">
            <h2 className='paragraph !text-4xl sm:!text-3xl px-3 sm:px-2 py-[.7rem] !w-full'>{props.label}</h2>
            <div className="flex sm:flex-col h-[60%] px-2 ml-auto gap-5 items-center">
                <PieChart
                    className='my-5 max-h-[15rem]'
                    data={pieChartData}
                    label={(data) => data.dataEntry.percentage}
                    labelPosition={65}
                    labelStyle={{
                        fontSize: "16px",
                        fontColor: "FFFFFA",
                        fontWeight: "500",
                        fontFamily: "Jipatha, san-serif"
                    }}
                />
                <div className="ml-5 pr-5">
                    {pieChartData.map((item) =>
                        <div className="flex gap-2 items-center">
                            <div className={` w-[14px] h-[14px] translate-y-[-3px]`}
                                style={{ background: `${item.color}` }}
                            ></div>
                            <p className='paragraph !w-[25ch]'>{item.title}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}


const Admin = () => {

    const [votes, setVotes] = useState([])

    useEffect(() => {
        fetchPost(setVotes)
        console.log(process.env.GOOGLE_AUTH_CLIENT_ID)
    }, [])


    const [isLogin, setIsLogin] = useState(false)
    const [showingPopup, setShowingPopup] = useState(true)

    return (

        <html lang='en' >
            <head > { /* <link rel="shortcut icon" href="" /> */}
                <meta name="theme-color" value='#36C1A3' />
            </head>
            <body className="bg-pattern ">
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
                    <Login setIsLogin={setIsLogin} setShowingPopup={setShowingPopup}
                        showingPopup={showingPopup}
                    />
                </GoogleOAuthProvider>

                {
                    isLogin &&
                    <div className="content">
                        <div className='px-[5vw] sm:px-[1rem] py-[2rem] max-w-[1000px] sm:w-full w-[90vw] ml-auto mr-auto'>
                            <div className="flex justify-between items-center mb-[4rem]">
                                <a draggable="false" href="/" className="block w-[40vw] max-w-[300px]"><img draggable="false" src={logoFull} alt="RYCD Logo" /></a>
                                <button className='' onClick={() => fetchPost(setVotes)}>
                                    <RefreshIcon className='h-[2.5rem] w-[2.5rem]  sm:h-[30px] sm:w-[30px] active:rotate-[360deg] transition-all'></RefreshIcon>
                                </button>
                            </div>
                            <div className="flex gap-3 justify-between  bg-white rounded-lg px-3 sm:px-2 py-[.9rem] mb-7 shadow-[0_10px_10px_10px_rgba(0,0,0,0.1)] ">
                                <h1 className='paragraph items-baseline !text-[2.6rem] sm:!text-[2rem] !w-auto flex gap-2'> คำตอบทั้งหมด
                                    <span className='heading-1 !text-[4rem] '>{votes.length}</span>
                                    คำตอบ
                                </h1>
                                <ExportButton json={votes} />
                            </div>
                            <PieChartBox label="เพศ" items={votes.map((i) => i.userGender)} />
                            <PieChartBox label={"อายุ"} items={votes.map((i) => i.userAge)} />
                            <PieChartBox label={"อาชีพ"} items={votes.map((i) => i.userJob)} />
                            <PieChartBox label="มีทะเบียนบ้านในระยอง" items={votes.map((i) => i.haveAddress)} />
                            <PieChartBox label="คุณอยากทดลองใช้บริการรถสาธารณะเส้นทางไหนมากที่สุด" items={votes.map((i) => i.route)} isRouteData={true} />
                            <TextCommentBox label={"คำแนะนำเพิ่มเติมของเส้นทางทดลองที่คุณเลือก"} items={votes.map((i) => i.comment)} />
                            <TextCommentBox label={"ออกความคิดเห็นเพื่อเสนอเส้นทางเพิ่มเติม"} items={votes.map((i) => i.suggestion)} />

                        </div >
                    </div>
                }

            </body>
        </html >
    )
}

export default Admin
