export const formatColorToHex = (busColor) => {
    try {
        let colors = busColor.split("-");
        colors.forEach((color, i) => {
            if (color == "blue") colors[i] = "#2C93F0"
            else if (color == "navy") colors[i] = "#1C4587"
            else if (color == "yellow") colors[i] = "#FBBC04"
            else if (color == "dark green") colors[i] = "#274E13"
            else if (color == "orange") colors[i] = "#FF6D01"
            else if (color == "pink") colors[i] = "#E06666"
            else if (color == "red") colors[i] = "#CC0000"
            else if (color == "dark red") colors[i] = "#990000"
            else if (color == "purple") colors[i] = "#9900FF"
            else if (color == "gray") colors[i] = "#535353"
            else if (color == "white") colors[i] = "#eeeeee"
        })
        return colors
    } catch (error) {
        console.log(error)
        console.log(busColor)
    }
}

export const formatRouteColor = (busColor) => {
    try {
        let colors = busColor
        if (colors == "yellow-white") colors = "black"
        else if (colors == "white-yellow") colors = "black"
        else if (colors == "white") colors = "black"
        // else if (colors == "gray") colors = "black"


        colors = colors.split("-");
        colors.forEach((color, i) => {
            if (color == "blue") colors[i] = "#2C93F0"
            else if (color == "navy") colors[i] = "#1C4587"
            else if (color == "yellow") colors[i] = "#FBBC04"
            else if (color == "dark green") colors[i] = "#274E13"
            else if (color == "orange") colors[i] = "#FF6D01"
            else if (color == "pink") colors[i] = "#E06666"
            else if (color == "red") colors[i] = "#CC0000"
            else if (color == "dark red") colors[i] = "#990000"
            else if (color == "purple") colors[i] = "#9900FF"
            else if (color == "gray") colors[i] = "#535353"
            else if (color == "black") colors[i] = "#000000"
            else if (color == "white") colors[i] = "#eeeeee"
        })
        return colors
    } catch (error) {
        console.log(error)
        console.log(busColor)
    }
}


export const formatEndName = (endName) => {
    let haveWaveSymbol = false
    let buffer = endName.split(",")
    if (buffer[buffer.length - 1] == "wave") {
        haveWaveSymbol = true
        buffer.pop()
    }
    return [buffer, haveWaveSymbol]
}

export const formatSpecialRouteColor = (color) => {
    let formattedColor = "#CC0000"
    if (color == "red") { formattedColor = "#C95E5E" } else if (color == "green") { formattedColor = "#519E59" } else if (color == "blue") { formattedColor = "#489BFD" } else if (color == "navy") { formattedColor = "#516D9B" }

    return formattedColor
}