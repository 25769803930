import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import thaiFlagIcon from "./assets/thai-flag-icon.png"
import usaFlagIcon from "./assets/usa-flag-icon.png"

const LanguageButton = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("th");

    const handleToggle = () => {
        var buffer;
        setLanguage(prev => {
            if (prev == "th") buffer = "en"
            else buffer = "th"

            console.log(buffer)

            i18n.changeLanguage(buffer);
            return buffer
        })

    }
    return (
        <div onClick={handleToggle} className="sm:!right-[.5rem] sm:w-[30px] sm:h-[30px] lg:right-[calc(8vw-2rem)] sm:top-[1.5rem] !z-50 overflow-hidden rounded-full border-2 absolute w-[36px] h-[36px] right-[5vw] top-[2rem] bg-white cursor-pointer select-none">
            {
                language == "th" ? (
                    <img src={thaiFlagIcon} alt="thai flag icon" />
                ) : (
                    <img src={usaFlagIcon} alt="usa flag icon" />
                )
            }
        </div>
    );
};

export default LanguageButton;
