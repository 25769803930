import React, { useState } from 'react'
import ArrowRightIcon from "./assets/arrow-right-solid.jsx"
import WaveIcon from "./assets/wave-icon.png"
import { formatEndName, formatColorToHex } from './formatter.js'
import { useTranslation } from "react-i18next";

const BusDisplayCard = (props) => {

    const [colorHex, setColorHex] = useState(formatColorToHex(props.busColor))
    const [formattedEndName, setFormattedEndName] = useState(formatEndName(props.endName))
    const { t, i18n } = useTranslation();

    return (
        <div dir="ltr" className='sm:gap-x-1 flex items-center gap-x-2'>
            <div className={`sm:w-3 sm:h-2 sm:rounded w-6 h-[6px]`} style={{ background: `${colorHex[0]}` }}></div>
            <p className='[word-spacing:-2.0px] sm:text-2xl sm:leading-3 font-jipatha leading-[.5rem] text-[1.6rem] tracking-wide flex items-center gap-2 sm:gap-1'>
                {t(props.startName)}
                {props.endName != "" &&
                    <ArrowRightIcon className="sm:w-3 sm:h-3 w-4 h-4 fill-gray-600" />}
                {t(formattedEndName[0][0])}
                {
                    formattedEndName[0][1] &&
                    <ArrowRightIcon className="w-4 h-4 fill-gray-600" />
                }

                {t(formattedEndName[0][1])}
                {
                    formattedEndName[1] && <img src={WaveIcon} className="w-4 h-4 fill-gray-600" />
                }
            </p>

        </div >
    )
}

export default BusDisplayCard