import React from 'react'

const ErrorPage = () => {
  return (
    <div className='gradient h-screen w-screen bg-gradient flex justify-center items-center'>
        <div className="flex flex-col items-center">
            <h1 className='font-jipatha text-white text-[8rem] h-[8rem]'>404</h1>
            <h3 className='font-jipatha text-white text-[2rem]'>The page you are looking for does not exist.</h3>
        </div>
    </div>
  )
}




export default ErrorPage