import React, { useState } from 'react'

const ToggleSwitch = (props) => {

    return (
        <label class="switch" >
            <input type="checkbox" checked={props.checked} onChange={props.toggleCheck} />
            <span class="slider round"></span>
        </label>
    );
};

export default ToggleSwitch;