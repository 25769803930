import React from 'react'
import BusDisplayCard from './BusDisplayCard'

const BusDisplay = (props) => {
    return (
        <div dir="rtl" className='lg:left-3 small-scroll sm:overflow-y-auto sm:max-h-[10rem] sm:gap-2 sm:top-[13vh] !z-30 flex flex-col bg-white absolute p-3 px-4 shadow-xl top-[18vh] left-[5rem] rounded-md gap-3 max-h-[73vh] overflow-y-auto'>
            {
                props.routes.map((route) => {
                    return route.showing &&
                        <BusDisplayCard
                            id={route.id}
                            startName={route.start_name}
                            endName={route.end_name}
                            routeColor={route.route_color}
                            busColor={route.bus_color}
                        />
                })
            }
        </div>
    )
}

export default BusDisplay