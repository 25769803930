import React, { useState, useEffect } from 'react'
import ToggleSwitch from './ToggleSwitch'
import SettingsGroup from './SettingsGroup'
import BusToggleCard from './BusToggleCard'
import { ReactComponent as ArrowIcon } from './assets/arrow-thin.svg'
import { ReactComponent as ArrowWhiteIcon } from './assets/arrow-thin-white.svg'
import { ReactComponent as ArrowDegIcon } from './assets/arrow-deg.svg'
import { useTranslation } from "react-i18next";
import motorbikeImage from "./assets/motorbike.png"


const Settings = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const { t, i18n } = useTranslation();


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    const isMobile = width <= 639;
    const isTablet = width <= 1023;
    var sBrowser, sUsrAg = navigator.userAgent;
    const isSafari = sUsrAg.indexOf("Safari") > -1

    const showTranslationRight = isTablet ? "calc(5vw - 2rem)" : "5vw";
    const hideTranslationRight = "-23rem";

    const showTranslationTop = '0rem';
    const hideTranslationTop = `calc(-75vh + 95px)`;
    const [settingTranslationRight, setSettingTranslationRight] = useState(showTranslationRight);
    const [settingTranslationTop, setSettingTranslationTop] = useState(isMobile ? hideTranslationTop : showTranslationTop);

    useEffect(() => {
        if (isMobile) {
            props.setDisplayShowing(!(showTranslationTop == settingTranslationTop))
        } else if (isTablet) {
            props.setDisplayShowing(!(showTranslationRight == settingTranslationRight))
        } else {
            props.setDisplayShowing(true)
        }
    }, [])

    const toggleShowRight = () => {
        setSettingTranslationRight(prevState => {
            if ((isTablet || isMobile)) {
                props.setDisplayShowing(prevState === showTranslationRight)
            }
            if (prevState === showTranslationRight) {
                return hideTranslationRight
            } else {
                return showTranslationRight
            }
        })
    }

    const toggleShowTop = () => {
        setSettingTranslationTop(prevState => {
            props.setDisplayShowing(prevState === showTranslationTop)
            if (prevState === showTranslationTop) {
                return hideTranslationTop
            } else {
                return showTranslationTop
            }
        })
    }

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const minSwipeDistance = 0.0001

    const onTouchStart = (e) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e) => {
        let distance = e.targetTouches[0].clientY - touchStart
        setSettingTranslationTop(prev => prev + distance * .03)
        setTouchEnd(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd

        const isTopSwipe = distance > minSwipeDistance
        const isBottomSwipe = distance < -minSwipeDistance
        if (isTopSwipe || isBottomSwipe) handleSwipeToggle(isTopSwipe)
    }

    const handleSwipeToggle = (topSwipe) => {
        if ((settingTranslationTop === hideTranslationTop) && (topSwipe)) toggleShowTop()
        else if ((settingTranslationTop === showTranslationTop) && (!topSwipe)) toggleShowTop()

        if (settingTranslationTop >= hideTranslationTop) setSettingTranslationTop(hideTranslationTop)
        else if (settingTranslationTop <= showTranslationTop) setSettingTranslationTop(showTranslationTop)
    }
    return (
        <div className='sm:ml-auto sm:mr-auto sm:left-0 sm:!right-0 sm:top-auto w-[23rem] sm:w-[23rem] absolute transition-all duration-200 delay-50 !z-20 top-[12vh]' style={{ right: `${settingTranslationRight}`, bottom: `${settingTranslationTop}` }} >
            <div className={`sm:h-[75vh] max-h-[83vh] h-[83vh] w-[23rem] sm:w-[23rem] bg-white shadow-Yl rounded-xl delay-200 relative`}>
                {/* --header for mobile-- */}
                <div onClick={toggleShowTop} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove} className='hidden sm:flex flex-col items-center bg-green-500 gradient rounded-tl-lg rounded-tr-lg'>
                    <ArrowWhiteIcon className='w-[16px] pt-3 transition-all delay-250 duration-0 fill-white' style={{ rotate: `90deg` }} />
                    <h2 className='px-7 pb-6 font-opensan font-bold text-white text-3xl leading-5'>Route Settings</h2>
                </div>

                {/* --header for window-- */}
                <div className='sm:hidden flex bg-green-500 gradient rounded-tl-lg rounded-tr-lg'>
                    <h2 className='px-7 p-6 font-opensan font-bold text-white text-3xl'>Route Settings</h2>
                </div>

                <ul className='relative'>
                    <li className='list-element border-b-2 p-[0.25rem] pl-7 pr-5 border-gray-300'>
                        <h1 className="font-jipatha text-[2.4rem] text-gray-700 sm:text-[2.2rem]">{t('แสดงทั้งหมด')}</h1>
                        <ToggleSwitch checked={props.checkedAll} toggleCheck={props.toggleCheckedAll} />
                    </li>
                    <li className='overflow-auto overflow-x-hidden h-[calc(83vh-154px)] sm:h-[calc(75vh-174px)]'>
                        {
                            Object.keys(props.groupIndexes).map((key) =>
                                <SettingsGroup
                                    routes={props.routes.slice(props.groupIndexes[key][0], props.groupIndexes[key][1] + 1)}
                                    groupName={key}
                                    setRoutes={props.setRoutes}
                                    setShowingRoute={props.setShowingRoute}
                                    handleCheckedAll={props.handleCheckedAll}
                                />
                            )
                        }
                    </li>
                </ul>

                {/* --toggle show/hide switch-- */}
                <div onClick={toggleShowRight} className='sm:hidden flex top items-center justify-center cursor-pointer absolute h-[80px] w-[30px] top-[calc(50%-40px)] left-[-30px] bg-white !z-[4] rounded-tl-lg rounded-bl-lg shadow-[-2px_0px_5px_0px_rgba(0,0,0,0.1)_inset]'>
                    <span className='w-full h-full absolute rounded-tl-lg rounded-bl-lg shadow-[-2px_0px_5px_0px_rgba(0,0,0,0.2)]'></span>
                    <ArrowIcon className='w-[13px] transition-all delay-250 duration-0 ' style={{ rotate: `${settingTranslationRight === showTranslationRight ? "180deg" : "0deg"}` }} />
                </div>

                {/* --re-center button mobile-- */}
                {
                    settingTranslationTop != showTranslationTop && <div onClick={props.reCenter} className='sm:right-[0rem] hidden sm:flex sm:bottom-[78vh] cursor-pointer hover:bg-red-200 z-20 absolute right-[calc(23rem+3vw)] bottom-[0.2vh] w-[4.5rem] h-[4.5rem] rounded-full bg-white items-center justify-center shadow-lg'>
                        <ArrowDegIcon className='w-[70%] h-[70%]' />
                    </div>

                }

                {
                    settingTranslationTop != showTranslationTop && <div onClick={props.toggleShowingbike} style={{ background: `${props.showingBike ? "white" : "#cccccc"}` }} className='sm:right-[5.7rem] hidden sm:flex sm:bottom-[78vh] cursor-pointer hover:bg-red-200 z-20 absolute right-[calc(23rem+3vw)] bottom-[0.2vh] w-[3rem] h-[3rem] rounded-md bg-white items-center justify-center shadow-lg'>
                        <img src={motorbikeImage} alt="" />
                    </div>

                }

                {/* --re-center button-- */}
                <div onClick={props.reCenter} className='sm:hidden sm:bottom-[50rem] cursor-pointer hover:bg-slate-300 z-20 absolute right-[calc(23rem+3vw)] bottom-[0.2vh] w-[4.5rem] h-[4.5rem] rounded-full bg-white flex items-center justify-center shadow-lg'>
                    <ArrowDegIcon className='w-[70%] h-[70%]' />
                </div>
                <div onClick={props.toggleShowingbike} style={{ background: `${props.showingBike ? "white" : "#cccccc"}` }} className={'sm:hidden sm:bottom-[50rem] cursor-pointer hover:bg-slate-300 z-20 absolute right-[calc(23rem+3vw)] bottom-[calc(2.3vh+4.5rem)] w-[3rem] h-[3rem] rounded-md bg-white flex items-center justify-center shadow-lg'}>
                    <img src={motorbikeImage} alt="" />
                </div>
            </div >
        </div >
    )
}

export default Settings