import route1 from './route4.json'
import route2 from './route2.json'
import route3 from './route3.json'
import route4 from './route1.json'
import route0 from './route0.json'

const routes = [
    route0,
    route1,
    route2,
    route3,
    route4,
]
export default routes;