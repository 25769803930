import React, { useEffect, useState } from 'react'
import BusToggleCard from './BusToggleCard'
import { ReactComponent as ArrowIcon } from './assets/arrow-thick.svg'
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "react-i18next";
import ToggleSwitch from './ToggleSwitch';
import routes from './routes/routes';

const SettingsGroup = (props) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { t, i18n } = useTranslation();
    const [checked, setChecked] = useState(true);

    const toggleChecked = () => {
        setChecked(prev => !prev)
        if (!checked) {
            props.routes.forEach(route => {
                props.setShowingRoute(route.id, true)
            });
        } else {
            props.routes.forEach(route => {
                props.setShowingRoute(route.id, false)
            });
        }
    }

    const handleToggleGroup = () => {
        props.handleCheckedAll()
        let flag = true;
        props.routes.forEach(route => {
            if (route.showing == true) {
                setChecked(true);
                flag = false
            }
        });
        if (flag == true) {
            setChecked(false);
        }
    }

    // useEffect(() => {

    // }, [checked])

    const toggleIsExpanded = () => {
        setIsExpanded(prev => !prev)
    }
    return (
        <div className=''>
            <div onClick={toggleIsExpanded} className={`cursor-pointer px-7 select-none py-3 transition-all flex items-center justify-between border-b-2 ${isExpanded ? "shadow-lg" : ""}`} >
                <h3 className={`font-jipatha sm:text-[2.2rem] text-[2.3rem] ${props.groupName == "เส้นทางทดลอง" && "text-[#1C4587]"} leading-9`}>{t(props.groupName)}</h3>
                {
                    ["นอกเมือง", "ในเมือง"].includes(props.groupName) ?
                        <div className="flex flex-cols gap-5">
                            <ToggleSwitch checked={checked} toggleCheck={toggleChecked}></ToggleSwitch>
                            <span>
                                <ArrowIcon className='w-[12px] transition-all delay-250 duration-0 ' style={{ rotate: `${isExpanded ? "-90deg" : "90deg"}` }} />
                            </span>
                        </div> :
                        <span>
                            <ArrowIcon className='w-[12px] transition-all delay-250 duration-0 ' style={{ rotate: `${isExpanded ? "-90deg" : "90deg"}` }} />
                        </span>
                }
            </div>
            <div className='transition-all'>
                <AnimatePresence mode="wait">
                    {
                        isExpanded &&
                        props.routes.map((route) =>
                            <BusToggleCard
                                id={route.id}
                                startName={route.start_name}
                                endName={route.end_name}
                                routeColor={route.route_color}
                                busColor={route.bus_color}
                                showing={route.showing}
                                price={route.price}
                                distance={route.distance}
                                busType={route.bus_type}
                                setShowingRoute={props.setShowingRoute}
                                handleCheckedAll={handleToggleGroup}
                            />
                        )

                    }
                </ AnimatePresence>

            </div>
        </div>
    )
}

export default SettingsGroup