import React from "react";

function SimpleBusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.45 38.31" fill={props.colorMain} stroke={props.colorMain} className={props.className}>
      <path d="M49.35,20.38l-2.35-10.01h-13.38v12.96H3v7.8H7.57c.38-4.06,3.8-7.24,7.96-7.24s7.58,3.18,7.96,7.24h14.77c.27-3.55,2.89-6.62,6.56-7.27,4.35-.78,8.51,2.11,9.29,6.46,.05,.27,.08,.54,.1,.81h4.68v-8.91l-9.53-1.83Z" strokeLinejoin="round" strokeWidth={3} />
      <polygon points="46.07 6.55 40.32 3.75 29.13 3.75 4.03 3.75 4.03 5.55 4.03 19.54 29.63 19.54 29.45 6.55 46.07 6.55" strokeLinejoin="round" strokeLinecap="round" fill="#494646" stroke="#494646" />
      <circle cx="15.62" cy="31.73" r="3.23" strokeWidth={5} fill="#494646" stroke="#494646" />
      <circle cx="46.23" cy="31.73" r="3.23" strokeWidth={5} fill="#494646" stroke="#494646" />
      <g>
        <rect x="4.99" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
        <rect x="14" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
        <rect x="23.02" y="8.5" width="5.16" height="9.89" rx="1.45" ry="1.45" strokeLinecap="round" fill="white" stroke="white" strokeWidth={1} />
      </g>
      <rect x="1.49" y="23.19" width="58.88" height="1.5" fill={props.colorStripe} strokeWidth={0} />

    </svg>
  );
}

export default SimpleBusIcon;
