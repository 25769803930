import React, { useState } from 'react'
import route4markers from "./routes/special_routes/route4markers.json"
import { MarkerF, InfoWindowF } from '@react-google-maps/api';
import InfoMarkerIcon from './assets/map-marker-info.svg'


const Route4InfoMarkers = () => {
    const humanMarker = {
        url: InfoMarkerIcon,
        scaledSize: { height: 55, width: 55 }
    };

    const [markerHovered, setMarkerHovered] = useState(Array(route4markers.length).fill(false))
    const handleMarkerHover = (index) => {
        const updatedMarkerHovered = [false];
        updatedMarkerHovered[index] = true;
        setMarkerHovered(updatedMarkerHovered);
    }

    const handleMarkerOut = (index) => {
        const updatedMarkerHovered = [...markerHovered];
        updatedMarkerHovered[index] = false;
        setMarkerHovered(updatedMarkerHovered);
    };
    const origin = ({ lat: 12.697336182643589, lng: 101.26837821144515 })

    return (
        route4markers.map((marker, i) => (
            <MarkerF
                zIndex={10000}
                key={i}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={humanMarker}
                title="Rayong Bus Station 1"
                onMouseOver={() => handleMarkerHover(i)}
                onClick={() => handleMarkerHover(i)}
                onMouseOut={() => handleMarkerOut(i)}
                styles={
                    { transition: 'all 0.3s ease-out', } // Add transition property
                }

            >
                {markerHovered[i] && <InfoWindowF position={origin}>
                    <div className=''>
                        <h1 className='heading-1 w-[13ch] '>
                            {marker.name}
                        </h1>
                        <h3 className='paragraph !w-auto border-b-2 border-gray-400 mb-3'>เวลารถ</h3>
                        <ul className='grid grid-cols-2'>
                            {
                                marker.times.map((time, j) => (
                                    j != 2 ?
                                        <li className='paragraph !w-auto'>{time} น.</li> :
                                        <li className='paragraph !w-auto'>รอบชาร์ต</li>
                                ))
                            }
                        </ul>
                    </div>
                </InfoWindowF>}
            </MarkerF >

        ))
    )
}

export default Route4InfoMarkers