import React, { useMemo, useEffect, useState, useRef } from 'react'
import { GoogleMap, MarkerF, useLoadScript, DirectionsRenderer, PolylineF, InfoWindowF, CircleF, InfoBox } from '@react-google-maps/api';
import Settings from './Settings';

import BusDisplay from './BusDisplay';
import BikePin from './assets/bike-pin.svg'
import BusStationPin from './assets/bus-station-pin.svg'
import HumanIcon from './assets/human.png'

import { formatColorToHex, formatRouteColor } from './formatter';
import bikeLocation from './routes/bikeLocations.json'
import routesJson from './routes/routes'
import testRoutesJson from './routes/special_routes/routes'
import Route4InfoMarkers from './Route4InfoMarkers';

const RouteLine = (props) => {

    const colorInput = props.busColor
    const colors = formatColorToHex(colorInput)
    const routeColors = formatRouteColor(colorInput)
    var icon = {
        path: "M32.29,2.57c-12.22,0-22.13,9.91-22.13,22.13,0,10.07,13.49,28.01,19.4,35.41,1.42,1.76,4.05,1.76,5.46,0,5.91-7.4,19.4-25.33,19.4-35.41,0-12.22-9.91-22.13-22.13-22.13Zm0,32.21c-5.65,0-10.23-4.58-10.23-10.23s4.58-10.23,10.23-10.23,10.23,4.58,10.23,10.23-4.58,10.23-10.23,10.23Z",
        fillColor: colors[0],
        fillOpacity: 1,
        anchor: { x: 32, y: 64 },
        strokeWeight: 1,
        scale: .7
    }
    const endNames = props.endName.split(",")


    return (
        <div>
            {
                (!(endNames[0] == "เรือนจำ") && !(endNames[0] == "ขนส่ง 2")) &&
                <MarkerF
                    position={props.overviewPath[props.overviewPath.length - 1]}
                    title={endNames[endNames.length - 1]}
                    icon={icon}
                />
            }
            {
                endNames[0] == "สุขุมวิท" && <Route4InfoMarkers />
            }

            {routeColors[1] &&
                <PolylineF
                    path={props.overviewPath}
                    options={{
                        strokeColor: routeColors[1],
                        strokeOpacity: 1,
                        strokeWeight: 6
                    }}
                />}
            <PolylineF
                path={props.overviewPath}
                options={{
                    strokeColor: routeColors[0],
                    strokeOpacity: 1,
                    strokeWeight: 3
                }}
            />

        </div>
    )
}

const Map = () => {
    const mapRef = useRef(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });


    const hiding = [
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        },
    ]


    const RAYONG_BOUNDARY = {
        latLngBounds: {
            north: 12.712562067023294,
            south: 12.672295559834478,
            east: 101.32993560360273,
            west: 101.17402414583577,
        }
    };
    const [mapBounds, setMapBounds] = useState(RAYONG_BOUNDARY);
    const [mapZoom, setMapZoom] = useState(13)
    const handleFitBound = () => {
        setMapCenter({ lat: 12.683430187508316, lng: 101.27390397262539 })
        // mapRef.fitBounds(RAYONG_BOUNDARY)
        setMapZoom(10)
    }
    const defaultOptions = {
        styles: hiding,
        disableDefaultUI: true,
        minZoom: 12,
        maxZoom: 20,
        // fitBounds: {
        //     bounds: mapBounds, // Use the state variable to set initial bounds
        // },
        restriction: {
            latLngBounds: {
                // north: 19.137384, // Mumbai
                // south: 18.510866, // Pune
                // west: 72.874748,  // Mumbai
                // east: 73.879864,  // Pune
                north: 13.118228449875659,
                south: 12.471018149367833,
                east: 101.91254827174174,
                west: 100.74794487860252,
            },
            strictBounds: false,
        }
    }
    // { lat: 12.72825380858218, lng: 101.06722396024583 };
    const rayongBusStation1 = { lat: 12.683430187508316, lng: 101.27390397262539 }
    const rayongBusStation2 = { lat: 12.700803242420404, lng: 101.2162560579831 }
    const rayongTransportStation = { lat: 12.683373183998533, lng: 101.27369815388928 }
    const center = useMemo(() => (rayongBusStation1), []);
    const origin = ({ lat: 12.697336182643589, lng: 101.26837821144515 })
    const destination = ({ lat: 12.697336182643589, lng: 101.26837821144515 })

    const s = () => {
        if (isLoaded) {
            const directionsService = new window.google.maps.DirectionsService();
            // const directionsRenderer = new window.google.maps.DirectionsRenderer();
            calculateAndDisplayRoute(directionsService);
        }
    }

    const calculateAndDisplayRoute = (directionsService) => {
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                waypoints: [
                    // lo{ lat: 12.682922663719278, lng: 101.2884104017774 } { location: "Ban Khai Hospital, 144 ถนนบ้านค่าย-ระยอง Nong Lalok, Ban Khai District, Rayong 21120" },
                    // { location: "ร้านซ่อมเครื่องตัดไม้ (ช่างเอ) 3143, Nong Lalok, Ban Khai District, Rayong 21120" },
                    { location: "12.704374075678272, 101.2415323821796" },
                    { location: "12.689224727043687, 101.2393876086621" },
                    { location: "12.683251784000015, 101.2455345766235" },
                    { location: "12.683512307609439, 101.27355496139343" },

                    { location: "12.682023441845375, 101.27439588788762" },

                ],
                travelMode: "DRIVING"
            },
            (response, status) => {
                if (status === 'OK') {
                    // setRoute(response)
                    console.log(response)
                    // setR(response)
                } else {
                    console.error('Directions request failed:', status);
                }
            }
        )
    }


    const bikeMarker = {
        url: BikePin,
        scaledSize: { height: 55, width: 55 }
    };

    const humanMarker = {
        url: HumanIcon,
        scaledSize: { height: 40, width: 40 }
    };

    const busStationMarker = {
        url: BusStationPin,
        scaledSize: { height: 70, width: 70 }
    };

    // sort route by group name
    function compareByName(a, b) {
        return a.group.localeCompare(b.group);
    }

    const getGroupIndexes = () => {
        const groupIndexesBuffer = {}

        routes.forEach((route, i) => {
            if (!(route.group in groupIndexesBuffer)) {
                groupIndexesBuffer[route.group] = [i, i]
            } else {
                groupIndexesBuffer[route.group][1] = i
            }
        })
        return groupIndexesBuffer
    }
    const swapUrbanToFront = (routes) => {
        const buffer = [...routes]
        let count = 0;
        let firstIndex = buffer.findIndex(i => i.group == "ในเมือง")
        console.log(firstIndex)
        console.log("hello")
        routes.forEach(route => {
            if (route.group == "ในเมือง") count += 1;
        })
        console.log(count)
        let groupToMove = buffer.splice(firstIndex, count);
        return groupToMove.concat(buffer)
    }

    // const [routes, setRoutes] = useState(testRoutesJson.slice(1,).concat(swapUrbanToFront(routesJson.sort(compareByName))));

    const [routes, setRoutes] = useState(swapUrbanToFront(routesJson.sort(compareByName)));


    useEffect(() => {
        handleFitBound();
    }, []);

    const setShowingRoute = (id, state) => {
        const buffer = [...routes]
        buffer.forEach((route, i) => {
            if (id == "all") {
                buffer[i].showing = state
            } else if (id == route.id) {
                buffer[i].showing = state
            }
        })
        setRoutes(buffer)
    }

    const [r, setR] = useState(null);

    //set Check All to false when all toggle box is false and set to true if one toggle box is true
    const [checkedAll, setCheckedAll] = useState(true)
    const handleCheckedAll = () => {
        setCheckedAll(routes.some((route) => route.showing))
    }

    //handle Check All behavior and handle Showing Route
    const toggleCheckedAll = () => {
        setCheckedAll((prev) => !prev)
        setShowingRoute("all", !checkedAll)
    }

    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 639) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    const [mapCenter, setMapCenter] = useState(rayongTransportStation);

    const reCenter = () => {
        setMapCenter(userLocation)
    }

    const [groupIndexes, setGroupIndexes] = useState(getGroupIndexes())

    const [userLocation, setUserLocation] = useState();
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function success(pos) {
        var crd = pos.coords;
        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
        console.log(crd)
        setUserLocation({ "lat": crd.latitude, "lng": crd.longitude })
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    useEffect(() => {
        // setGroupIndexes(getGroupIndexes())
        window.addEventListener("resize", handleResize)

        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    console.log(result);
                    if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                        //If prompt then the user will be asked to give permission
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    const [displayShowing, setDisplayShowing] = useState(false)
    const circleOptions = {
        fillColor: "#2BAAD9",
        fillOpacity: 0.3,
        strokeWeight: 2,
        strokeColor: "#2BAAD9",
        clickable: false,
        zIndex: 1
    };

    const [showingBike, setShowingBike] = useState(false)
    const toggleShowingbike = () => {
        setShowingBike(prev => !prev)
    }


    return (
        <div className='map relative overflow-hidden'>
            {
                (routes.some((route) => route.showing) && displayShowing) &&
                <BusDisplay routes={routes} displayShowing={displayShowing} />
            }

            <Settings
                // s={s}
                routes={routes}
                setRoutes={setRoutes}
                setShowingRoute={setShowingRoute}
                checkedAll={checkedAll}
                setCheckedAll={setCheckedAll}
                handleCheckedAll={handleCheckedAll}
                toggleCheckedAll={toggleCheckedAll}
                reCenter={reCenter}
                groupIndexes={groupIndexes}
                setDisplayShowing={setDisplayShowing}
                toggleShowingbike={toggleShowingbike}
                showingBike={showingBike}
            />


            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                < GoogleMap
                    // ref={mapRef}
                    mapContainerClassName="map-container"
                    center={mapCenter}
                    zoom={mapZoom}
                    options={defaultOptions}
                    // restriction={RAYONG_BOUNDARY}
                    bootstrapURLKeys={{
                        key: [process.env.REACT_APP_GOOGLE_API_KE],
                        language: 'en',
                    }}
                >
                    {
                        routes.map((route) => {
                            return route.showing &&
                                < RouteLine
                                    overviewPath={route.overview_path}
                                    busColor={route.bus_color}
                                    endName={route.end_name}
                                />
                        })
                    }
                    <MarkerF position={userLocation} icon={humanMarker} title="Rayong Bus Station 1" />
                    <CircleF center={userLocation} radius={500} options={circleOptions} />
                    <MarkerF position={rayongBusStation1} icon={busStationMarker} tistle="Rayong Bus Station 1" />
                    <MarkerF position={rayongBusStation2} icon={busStationMarker} title="Rayong Bus Station 2" />
                    {
                        showingBike && bikeLocation.map((location) => (
                            <MarkerF position={{ lat: location[0], lng: location[1] }} icon={bikeMarker} />
                        ))
                    }
                    <DirectionsRenderer directions={r} options={{
                        polylineOptions: {
                            strokeColor: "red"
                        },
                        suppressMarkers: true
                    }} />
                </GoogleMap>
            )
            }
        </div >


    )
}

export default Map